import { Route, Routes } from 'react-router-dom';

import CreateSecret from './createSecret/CreateSecret';
import DisplaySecret from './displaySecret/DisplaySecret';
import Upload from './createSecret/Upload';

export const Routing = () => {
  return (
    <Routes>
      <Route path="/1f35a0a3-38a8-42ce-af21-a1c3aace870f" element={<CreateSecret />} />
      <Route path="/e32f5e15-25f3-44ed-8dab-5d11350205e2/upload" element={<Upload />} />
      <Route path="/:format/:key/:password" element={<DisplaySecret />} />
      <Route path="/:format/:key" element={<DisplaySecret />} />
    </Routes>
  );
};
